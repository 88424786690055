/// Computes a top-shadow for a card effect.
/// @param {Number} $depth - depth level
/// @return {List}
@function top-shadow($depth) {
  $primary-offset: nth(1.5 3 10 14 19, $depth) * 1px;
  $blur          : nth(1.5 3 10 14 19, $depth) * 4px;
  $color         : rgba(black, nth(.06 .12 .16 .25 .30, $depth));

  @return 0 $primary-offset $blur $color;
}

/// Computes a bottom-shadow for a card effect.
/// @param {Number} $depth - depth level
/// @return {List}
@function bottom-shadow($depth) {
  $primary-offset: nth(1.5 3 6 10 15, $depth) * 1px;
  $blur          : nth(1 3 3 5 6, $depth) * 4px;
  $color         : rgba(black, nth(.12 .11 .11 .11 .11, $depth));

  @return 0 $primary-offset $blur $color;
}


@mixin truncate($truncation-boundary) {
  max-width    : $truncation-boundary;
  white-space  : nowrap;
  overflow     : hidden;
  text-overflow: ellipsis;
}

@mixin box-shadow($depth) {
  @if $depth < 1 {
    box-shadow: none;
  }

  @else if $depth>5 {
    @warn "Invalid $depth `#{$depth}` for mixin `card`.";
  }

  @else {
    box-shadow: bottom-shadow($depth), top-shadow($depth);
  }
}

@mixin text-shadow-subtle($color: $black, $opacity: .4) {
  text-shadow: 1px 2px 3px rgba($color, $opacity);
}

@mixin glow($color: $primary) {
  text-shadow: 3px 3px 5px $color;
}

@mixin filter-drop-shadow() {
  filter: drop-shadow(2px 1px 4px rgba(0, 0, 0, .3));
}

@mixin devicon($tag, $filename) {
  background-image: url('/static/images/devicons/#{$tag}/#{$filename}.svg');

}