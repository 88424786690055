$tag-bg-color: #272822;
$txt-color0  : #f8f8f2;
$txt-color1  : #e6db74;
$txt-color2  : #f92672;
$txt-color3  : #66d9ef;
$txt-color4  : #fd971f;
$txt-color5  : #a6e22e;

.post-tags {
    margin-bottom: 1rem;

    .tag {
        box-shadow        : inset -2px 2px 3px 0px rgba(0, 0, 0, 0.16);
        height            : $tag-height;
        color             : $text;
        text-decoration   : none;
        // border         : 0 // 1px solid rgba(0, 0, 0, 0.1);

    }


    .tag-icon {
        background-image   : url(/static/images/hashtag.svg);
        background-position: center center;
        background-repeat  : no-repeat;
        background-size    : auto 85%;
        height             : 28px;
        width              : 28px;
        border-radius      : 50% 0 0 50%;
        padding-left       : 1.2em;



        &.javascript {
            @include devicon("javascript", 'javascript-original');
            background-color: #f0db4f !important;
        }

        &.nodejs {
            @include devicon("nodejs", 'nodejs-original');
            background-color: $nodejs-bg-color;
        }

        &.docker {
            @include devicon("docker", 'docker-original');
        }

        &.electron {
            @include devicon("electron", 'electron-original');
        }
    }
}

article.post-item {
    position: relative;

    h1.title {
        &::before {
            content                  : ' ';
            top                      : 1rem;
            left                     : calc(0px - calc(((3rem + 1.2em))));
            height                   : calc(2rem + 1.2em);
            width                    : calc(2rem + 1.2em);
            border-top-left-radius   : calc((2rem + 1.2em) /2);
            border-bottom-left-radius: calc((2rem + 1.2em) /2);
            position                 : absolute;
            background               : #3e3e3e url(/static/images/logo3db.png);
            background-repeat        : no-repeat;
            background-size          : cover; //auto 85%;
            background-position      : center;
            box-shadow               : inset -2px 2px 3px 0px rgba(0, 0, 0, 0.16);

        }
    }

    &.javascript {
        h1.title::before {
            background-color: #f0db4f;
            @include devicon("javascript", 'javascript-original');
        }
    }

    &.nodejs {
        h1.title::before {
            @include devicon("nodejs", 'nodejs-original-wordmark');
            background-color: $nodejs-bg-color;
        }
    }

    &.docker {
        h1.title::before {
            background-color: #019bc6;
            @include devicon("docker", 'docker-original');
        }
    }

    &.electron {
        /*         background-position        : bottom right;
        background                            : rgb(55, 120, 132);
        background                            : radial-gradient(circle, rgba(55, 120, 132, 1) 0%, rgba(109, 193, 208, 1) 94%);
        overflow                              : hidden; */

        h1.title::before {
            background-color: #d4e9ed;
            @include devicon("electron", 'electron-original');
        }
    }

    .all-posts {
        background: rgb(42, 93, 176);
        background: radial-gradient(circle, rgba(42, 93, 176, 1) 0%, rgb(134, 142, 255) 94%);
    }
}

.panel-block .tag.tag-icon {
    margin-right: 1rem;
}