body {
  text-rendering: geometricPrecision;
}

.content {

  h1,
  h2,
  h3,
  h4,
  h5 {
    font-weight   : $weight-bold;
    text-transform: capitalize;
    margin        : 1.5rem auto;
    border-radius : $control-radius;
  }

  h1 {
    margin        : 1rem 0;
    flex-direction: row;
    position      : relative;
    font-weight   : bold;
    background    : transparent;

    +.taglist {
      justify-content: space-around;
    }
  }
}

pre[class*="language-"] {
  border       : .8em solid rgba(0, 0, 0, 0.1);
  border-radius: $box-radius;
  margin       : .4rem auto;
}


.content.main, article {
  h1.title, h1 {
    font-size: 1.8rem;

    a {
      color: $content-heading-color;
    }
  }

  h2.sub.title {
    @extend .is-6;
    @include text-shadow-subtle($black, .15);
    color      : $primary-dark;
    font-weight: 475;
    text-align : jutify;
    margin     : 1rem 0;

    time {
      display      : block;
      text-align   : right;
      font-size    : 0.6rem;
      margin-top   : -1rem;
      margin-bottom: 1rem;
    }
  }



}

// post preview mainpage
.post-summary {
  border-top   : 1px dashed #ccc;
  border-bottom: 1px dashed #ccc;
  margin-bottom: 1rem;

  .media-content {
    overflow: hidden;
    padding : 1rem;
    position: relative;

    .read-more-btn {
      position   : absolute;
      line-height: 0;

      bottom: 10px;
      right : 4px;
      @include box-shadow(2);
    }
  }

  i[class*="quote"] {
    margin-right: 1rem;
    margin-left : -1rem;
    color       : $primary;
  }

  .post-preview {
    margin-left : 3rem;
    max-height  : 22em;
    position    : relative;
    line-height : 1.4em;
    border-left : 4px solid $primary;
    padding-left: 1rem;
    overflow    : hidden;
    opacity     : .75;

    &:after {
      content         : ' ';
      position        : absolute;
      bottom          : -1px;
      left            : 0;
      background-image: linear-gradient(#fafafa00, #fafafaff);
      height          : 4em;
      display         : block;
      width           : 100%;
      text-align      : center;
      color           : #fafafa;
    }

    * {
      font-size: 90%
    }

    :first-child {
      margin-top: 0;
    }

  }

  .read-more {
    width     : 100%;
    margin    : 0;
    padding   : 10px 0;
    text-align: center;
  }
}

//post lists
.content.main.postslist {

  h1 {
    @extend .is-5
  }

  h2,
  h2.sub.title {
    @extend .is-8;
    margin: 1rem 0;
  }
}


// post page

all-posts {
  padding                : 0;
  margin                 : 0;
  background             : #1a1e24;
  width                  : 100%;
  min-height             : 100vh;
  display                : -webkit-box;
  display                : -ms-flexbox;
  display                : flex;
  -webkit-box-align      : center;
  -ms-flex-align         : center;
  align-items            : center;
  -webkit-box-pack       : center;
  -ms-flex-pack          : center;
  justify-content        : center;
  -webkit-font-smoothing : antialiased;
  -moz-osx-font-smoothing: grayscale;
}