.logo {
  text-align: center;

  img {
    height        : 200px;
    display       : block;
    margin        : auto;
    mix-blend-mode: hard-light;
    filter        : drop-shadow(30px -20px 15px #101010);
  }
}

.monitor-light {
  background-image     : url('/static/images/light.png');
  background-position  : 50% 0;
  background-repeat    : no-repeat;
  background-size      : 100% auto;
  background-blend-mode: multiply;
  background-color     : $monitor-light-bg; // #2b3661;
  @include box-shadow(4);
}

nav.navbar {
  //box-shadow      : 0px -30px 60px 30px #60779885;
  border-radius   : $box-radius;
  text-align      : center;
  background-color: $secondary;
  align-items     : center;
  display         : flex;
  width           : 50%;
  margin          : 0 auto;
  min-height      : auto;

  .navbar-custom-menu {
    flex-grow  : 1;
    flex-shrink: 0;
    padding    : 0 1rem;
    display    : flex;
  }

  .navbar-item:not(.has-dropdown),
  .navbar-link {
    color          : darken($white, 20);
    display        : flex;
    flex-grow      : 1;
    margin         : .5rem;
    justify-content: center;
    padding        : .3rem .5rem;
    border-radius  : 8px;

    &.active {
      color         : $white;
      font-family   : $family-monospace;
      text-transform: uppercase;

      &:after {
        @extend .blink;
        content     : ' ';
        border-right: 2px solid darken($white, 30);
        width       : 3px;
        height      : 1.1em;
        display     : inline-block;
        margin-top  : .2em;
      }

      &:not(:hover) {
        background-color: darken($navbar-item-hover-background-color, 60);
      }
    }
  }

  .navbar-link:not(.is-arrowless) {
    padding-right: 1.75em;

    &:after {
      font-size: .7em;
      opacity  : .7;
    }
  }

  .navbar-item.has-dropdown {
    &:hover .navbar-link {
      background-color: transparent;
    }

    .navbar-dropdown {
      background-color          : #ffffff99;
      border-bottom-left-radius : 6px;
      border-bottom-right-radius: 6px;
      border-top                : 2px solid #dbdbdb;
      box-shadow                : 0 8px 8px rgba(10, 10, 10, 0.1);
      display                   : none;
      font-size                 : .875rem;
      left                      : 0;
      min-width                 : 100%;
      position                  : absolute;
      z-index                   : 20;
      padding                   : 0 .25rem;

      a {
        color: $text;
      }

      .navbar-item {
        text-align     : left;
        transition     : 0;
        justify-content: unset;

        &:not(:last-child) {
          border-bottom: 1px dashed #ccc;
        }

        &:hover {
          background-color: #00000099;
          color           : #fff;
        }
      }
    }

    &:hover .navbar-dropdown {
      display: block;
    }

    // if monokai
    .monocode {
      font-family: 'Consolas', $family-monospace;
      font-size  : 1.5em;
    }

    &.monokai {
      background: #272822;
      color     : #f8f8f2;

      .token.keyword,
      .token.operator {
        color: #f92672;
      }

      .token.string,
      a .token.string {
        color: #e6db74;
      }

      a {
        text-transform   : lowercase;
        color            : inherit;
        // text-shadow   : 0 0 2px rgba(255, 255, 255, 0.4), 0 0 5px rgba(255, 255, 255, 0.4), 0 0 10px #f1c916ab, 0 0 15px #d3c50994, 0 0 20px #a1920b86;
        padding          : 0;
        border-bottom    : 0;
      }
    }
  }
}

// Swup Animations
.navbar-item {
  transition: background-color .4s;

  span {
    transition: transform .4s;
  }
}

html.is-animating,
html.is-changing {
  nav .navbar-item {
    overflow: hidden;
  }

  ::after {
    visibility: hidden;
  }
}

html.is-leaving {
  .monitor-light {
    animation: monitor-blink linear .43s;
  }

  .monitor-light .logo img {
    animation: monitor-shadow linear .4s;
  }

  .navbar-item {
    animation: fadein linear .4s reverse;

    span {
      animation: swipe-from-left linear .4s reverse;
    }
  }
}

html.is-rendering {
  .monitor-light {
    animation: monitor-blink2 linear .4s;
  }

  .monitor-light .logo img {
    animation: monitor-shadow2 linear .4s;
  }

  .navbar-item {
    animation: fadein linear .4s;

    span {
      animation: swipe-from-left linear .4s;
    }
  }
}

@keyframes monitor-blink {
  from {
    background-color: $monitor-light-bg;
  }

  to {
    background-color: $link; // #235;
  }
}

@keyframes monitor-blink2 {
  from {
    background-color: $link; // #235;
  }

  to {
    background-color: $monitor-light-bg;
  }
}

@keyframes monitor-shadow {
  from {
    filter: drop-shadow(30px -20px 15px #222);
  }

  to {
    filter: drop-shadow(-80px 0px 40px #222);
  }
}

@keyframes monitor-shadow2 {
  from {
    filter: drop-shadow(-80px 0px 40px #222);
  }

  to {
    filter: drop-shadow(30px -20px 15px #222);
  }
}

@keyframes swipe-from-left {
  from {
    //transform: translate3d(-60px, 0, 0);
    transform: rotateX(90deg);
  }

  to {
    //transform: translate3d(0px, 0, 0);
    transform: rotateX(0);
  }
}

@keyframes fadein {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

// blinking cursor animation
.blink {
  animation        : blink-animation 1.5s steps(2, start) infinite;
  -webkit-animation: blink-animation 1.5s steps(2, start) infinite;
}

@keyframes blink-animation {
  to {
    visibility: hidden;
  }
}

@-webkit-keyframes blink-animation {
  to {
    visibility: hidden;
  }
}