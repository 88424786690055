body {
    background-image: $body-background-image;
}

.button.rss {
    background-color: #ff7100;
    color           : #fff;
    border-radius   : 3px;
    @include box-shadow(3);
    @include text-shadow-subtle();

    .icon img {
        @include filter-drop-shadow();
    }
}

