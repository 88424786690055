@charset "utf-8";
@import url('https://fonts.googleapis.com/css?family=Space+Mono:400,400i&display=swap');
@import url('https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.11.2/css/all.min.css');
@import url(/static/css/segoe.css);
@import url(/static/css/menlo.css);



@import "theme_variables"; // need to set it before importing bulma - so css is generated including those
@import "node_modules/bulma/bulma.sass";

/* @import "node_modules/bulma/sass/utilities/_all.sass";
@import "node_modules/bulma/sass/base/_all.sass";

@import "node_modules/bulma/sass/elements/box.sass";
@import "node_modules/bulma/sass/elements/button.sass";
@import "node_modules/bulma/sass/elements/container.sass";
@import "node_modules/bulma/sass/elements/content.sass";
@import "node_modules/bulma/sass/elements/icon.sass";
@import "node_modules/bulma/sass/elements/image.sass";
@import "node_modules/bulma/sass/elements/notification.sass";
@import "node_modules/bulma/sass/elements/progress.sass";
@import "node_modules/bulma/sass/elements/table.sass";
@import "node_modules/bulma/sass/elements/tag.sass";
@import "node_modules/bulma/sass/elements/title.sass";
@import "node_modules/bulma/sass/components/_all.sass";
@import "node_modules/bulma/sass/grid/_all.sass"; */
// @import "node_modules/bulma/sass/layout/_all.sass";

/* override */

.number {
  align-items     : unset;
  background-color: unset;
  border-radius   : unset;
  display         : unset;
  font-size       : unset;
  height          : unset;
  justify-content : unset;
  min-width       : unset;
  margin-right    : unset;
  padding         : unset;
  text-align      : unset;
  vertical-align  : unset;
}

@import "mixins";
@import "layout_styles";
@import "text_styles";
@import "nav_styles";
@import "image_styles";
@import "elements";
@import "tags_and_heros";