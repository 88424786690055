.container {
  max-width: $container-max-width;

  &.content {
    margin-bottom: 0;
    padding      : 3rem;

    .glass {
      position: absolute;
      width   : 100%;
      height  : 100%;
      top     : 0;
      left    : 0;
    }
  }

  &.main {
    padding         : 0;
    margin          : 0 auto;
    background-color: #efefef;

    @include box-shadow(3);

    h1.title {
      margin     : 0 0 1rem;
      padding-top: 1rem;
    }

    >* {
      padding-left : 1rem;
      padding-right: 1rem;

      .no-padding {
        margin-left : -1rem;
        margin-right: -1rem;
      }


    }
  }
}

section.post-info {
  background-color: $post-info-bg;
  padding-top     : .5rem;
  padding-bottom  : .5rem;

  h1:first-child {
    margin: 0;
  }
}

footer.footer {
  padding    : 1rem 1rem 2rem;
  font-size  : 75%;
  font-weight: 100;

  .rss {
    float: right;
  }
}