@import "node_modules/bulma/sass/utilities/initial-variables";
$family-monospace: "Menlo",
"Consolas",
"Space Mono",
"Monaco",
monospace;
//$black: rgb(11, 14, 26);
$white : #ffffff;
$text  : #161e2e; // #020202; // $black;

$primary                           : #3D4658; // #466c95;
$primary-dark                      : #020202;
$secondary                         : #111429;
$link                              : #3f83f8;
$family-primary                    : "Segoe UI"; // $family-monospace;
$family-code                       : "Menlo";
$family-monospace                  : $family-code;
$button-family                     : $family-code;
$body-background-color             :#D1DADE;
$body-background-image             : url(/static/images/bodybg.svg);
$body-size                         : 20px;
$body-family                       : $family-primary;
$body-color                        : $text;
$body-font-size                    : 20px;
$body-line-height                  : 1.6em;
$content-heading-color             : #020202; // $text;
$content-heading-line-height       : 1.3em;
$box-shadow                        : none; //no box shadow on boxes by default
$radius                            : 0; // nothing is rounded 
$control-radius                    : 0;
$box-radius                        : 0;
$notification-radius               : 0;
$navbar-dropdown-arrow             : $white;
$navbar-item-hover-background-color: rgba(255, 255, 255, .1);

//custom vars

$container-max-width        : 800px; // $desktop+4;
$tag-height                 : 28px;
$monitor-light-bg           : #3D4658; // #1b6de3; //#323942; // $primary-dark; 
$monitor-light-header-height: 200px;
$post-info-bg               : #c9cde8;


$nodejs-bg-color: #b6f16d