.navbar .avatar {
    margin: auto;

    img {
        background: linear-gradient(45deg, #405de6, #5851db, #833ab4, #c13584, #e1306c, #fd1d1d);
        padding   : 6px;
        max-height: unset;
        @include box-shadow(2);
    }
}

.hallucination-effect {
    position             : relative;
    overflow             : hidden;
    background-color     : magenta;
    background-blend-mode: screen;
}

.hallucination-effect:before,
.hallucination-effect:after {
    display       : block;
    content       : "";
    position      : absolute;
    top           : 0;
    left          : 0;
    right         : 0;
    bottom        : 0;
    background    : inherit;
    mix-blend-mode: multiply;
    transform     : scale(1.05);
}

.hallucination-effect:before {
    background-color     : yellow;
    background-blend-mode: screen;
    transform-origin     : top left;
}

.hallucination-effect:after {
    background-color     : cyan;
    background-blend-mode: screen;
    transform-origin     : bottom right;
}